var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "my-5" }, [
    _c(
      "div",
      { staticClass: "loading align-center px-5 py-3 rate d-none d-sm-flex" },
      [
        _c(
          "v-container",
          {
            staticClass: "d-flex flex-column align-center justify-center my-3",
            attrs: { fluid: "" },
          },
          [
            _c(
              "v-carousel",
              {
                attrs: {
                  cycle: "",
                  height: "60",
                  interval: "800",
                  "hide-delimiter-background": "",
                  "hide-delimiters": "",
                  "show-arrows": false,
                },
                model: {
                  value: _vm.currentIndex,
                  callback: function ($$v) {
                    _vm.currentIndex = $$v
                  },
                  expression: "currentIndex",
                },
              },
              _vm._l(_vm.providers, function (provider, i) {
                return _c(
                  "v-carousel-item",
                  {
                    key: i,
                    attrs: { transition: "slide-x-reverse-transition" },
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "fill-height",
                        attrs: { "align-center": "", "justify-center": "" },
                      },
                      [
                        _c("v-img", {
                          staticClass: "my-4",
                          attrs: {
                            src: provider.image,
                            height: "30",
                            contain: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "textDark--text font-weight-medium py-5" },
              [_vm._v(" Finding best rates... ")]
            ),
            _c("v-progress-linear", {
              attrs: { indeterminate: "", color: "greenPrimary" },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }